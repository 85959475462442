import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Checkout from "./components/registrar_aluno/checkout";
import { DataProvider } from "./components/registrar_aluno/DataContext";
import { AuthProvider } from "./components/authContext";
import { AdminProvider } from "./components/app_admin/adminContext";
import { UploadProvider } from "./components/app_admin/uploadContext";
import AppAluno from "./components/app_aluno/main";
import AppAdmin from "./components/app_admin/main";

const AppRoutes = () => {
   return(
    
    <BrowserRouter basename="/">
        <DataProvider>
            <Routes>
                <Route element = { <Checkout /> }  path="/registrar-aluno/:turma" />   
            </Routes>
        </DataProvider>
        <AuthProvider>
            <Routes>
                <Route element = { <AppAluno /> }  path="/" />
            </Routes>
            <AdminProvider>
                <UploadProvider>
                    <DataProvider>
                        <Routes>
                            <Route element = { <AppAdmin /> }  path="/admin" /> 
                        </Routes>
                    </DataProvider>
                </UploadProvider>
            </AdminProvider>
        </AuthProvider>  
    </BrowserRouter>
   )
}

export default AppRoutes;