import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAdminData } from './adminContext';
import CadastroInstituicao from './cadastroInstituicao';

const Instituicao = () => {
  
  const {instituicoes, actionTrigger, setActionTrigger} = useAdminData();
  const [ cad_type, setCadType ] = React.useState('');
  const [ openCadastro, setOpenCadastro] = React.useState(false);
  const [ selectedInstData, setSelectedInst] = React.useState({});

  const handleCadClose = () =>{
    setOpenCadastro(false);
    setCadType('');
    setActionTrigger(!actionTrigger);
  };
  const handleOpenCad = (cad_type) => {
    setOpenCadastro(true);
    setCadType(cad_type);
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {instituicoes ? (instituicoes.map((instituicao) => (
              <TableRow key={instituicao.id}>
                <TableCell>{instituicao.id}</TableCell>
                <TableCell>{instituicao.nome}</TableCell>
                <TableCell align="right">
                  <Button variant="outlined" color="primary" style={{ marginRight: '5px' }} onClick={() => {handleOpenCad('ver'); setSelectedInst(instituicao)}}>Detalhes</Button>
                  <Button variant="outlined" color="primary" style={{ marginRight: '5px' }} onClick={() => {handleOpenCad('editar'); setSelectedInst(instituicao)}}>Editar</Button>
                </TableCell>
              </TableRow>
            ))): (<p>Carregando...</p>)}
          </TableBody>
        </Table>
      </TableContainer>
      <CadastroInstituicao open={openCadastro} onClose={handleCadClose} type={cad_type} inst_data={selectedInstData}/>
    </div>
  );
};

export default Instituicao;
